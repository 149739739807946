<template>
  <div>
    <InsuranceSkeleton v-if="loading" />
    <div class="insurance-selection" v-else>
      <div class="insurance-resume-container">
        <InsuranceDetailResume :insurance="selectedInsurance" />
        <InsuredValue class="insured-value" :propValue="selectedInsurance.insured_value" />
      </div>
    </div>
    <hr class="divisor-line" />
    <Rates v-if="selectedInsurance" :selectedInsurance="selectedInsurance" />
  </div>
</template>

<script>
import InsuranceDetailResume from "./components/InsuranceDetailResume.vue";
import InsuranceSkeleton from "./components/InsuranceSkeleton.vue";
import InsuredValue from "./components/InsuredValue.vue";
import Rates from "./components/Rates.vue";

export default {
  name: "InsuranceSelection",
  components: {
    InsuranceSkeleton,
    InsuranceDetailResume,
    InsuredValue,
    Rates,
  },
  data() {
    return {
      selectedInsurance: null,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.selectedInsurance = await this.getInsuranceDetailById();
    this.loading = false;
  },
  methods: {
    async getInsuranceDetailById() {
      const insurance = await this.$store.dispatch(
        "insurance/getInsuranceById",
        this.$route.params.id
      );
      return insurance;
    },
    getInsuranceDetailVuex() {
      const insurance = this.$store.getters["insurance/getInsuranceById"](
        this.$route.params.insuranceId
      );
      return insurance;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-resume-container {
  display: flex;
  justify-content: space-between;
}
.insured-value {
  width: 30%;
  min-width: 200px;
}
hr {
  border-color: $color-border-container;
}

@media (max-width: 800px) {
  .insurance-resume-container {
    flex-direction: column;
  }
  .insured-value {
    width: 100%;
  }
}
</style>
