<template>
  <div>
    <div class="load-resume-skeleton">
      <div class="info-skeleton">
        <Skeleton class="mb-3 mt-3" width="70%"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="75%"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="80%"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="90%"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="30%"></Skeleton>
        <Skeleton class="mb-3 mt-3" width="50%"></Skeleton>
        <div class="button-skeleton">
          <Skeleton class="mb-4 mt-3 button-skeleton" width="300px" height="40px"></Skeleton>
        </div>
      </div>
      <div class="insurance-skeleton">
        <Skeleton class="mb-2 mt-3 insurance-skeleton__text"></Skeleton>
        <Skeleton
          class="mb-2 mt-3 insurance-skeleton__input"
          height="40px"
        ></Skeleton>
      </div>
    </div>
    <div class="skeleton-list">
      <div class="md:col-8">
        <Skeleton class="skeleton-carrier__item mt-2" height="150px" width="100%"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "InsuranceSkeleton",
  components: {
    Skeleton,
  },
  data() {
    return {
      screenWidth: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.load-resume-skeleton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (width <= 800px) {
    flex-direction: column;
  }
}

.info-skeleton {
  width: 75%;
  @media (width <= 800px) {
    width: 100%;
  }
}

.button-skeleton {
  @media (width <= 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.insurance-skeleton {
  width: 25%;
  &__text{
    width: 70% !important;
  }
  &__input{
    width: 200px;
  }
  @media (width <= 800px) {
    width: 100%;
  }
}

::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
