<template>
  <div class="rate-option">
    <div class="image-container">
      <img src="../../../../assets/images/cargo-care-logo.png" alt="Insurance" />
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">Type of Coverage</span>
        <p class="section-container__text">
          {{ selectedInsurance.condition === "Brand New Goods" ? "All Risk" : "TOTAL LOSS" }}
        </p>
        <p class="section-container__text">
          {{ selectedInsurance.condition === "Brand New Goods" ? "ICC (A)" : "ICC (C)" }}
        </p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">Special Conditions</span>
        <p class="section-container__text">
          {{ selectedInsurance.deductible }}
        </p>
        <p class="section-container__text">
          Shipments with certain carriers are subject to underwritters approval
        </p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">Quote Expiration Date</span>
        <p class="section-container__text">{{ rate.expiration_date }}</p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <button class="quote-button quote-button--blue" @click="selectInsuranceOption()">
        <div class="price-container">
          <div class="price-container__label">BOOK</div>
          <div class="price-container__prices">
            <span>USD{{ rate.customer_cost.toFixed(2) | priceToUsd }}</span>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateOption",
  props: {
    rate: Object,
    selectedInsurance: Object,
  },
  methods: {
    selectInsuranceOption() {
      this.$emit("handlerInsuranceAcceptanceModal", true);
    },
    changeView(view) {
      this.$router.push({ name: view, params: { id: this.$route.params.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-option {
  //   @include font-small-standart-text;
  font-size: 13px;
  display: grid;
  grid-template-columns: 15% 17% 25% 19% 24%;
  width: 100%;
  border: 1px solid $color-grey-border;
  border-radius: 20px;
  background: $color-white;
  box-shadow: 0px 0px 6px #00000029;
  color: $color-primary-company;
  @media (min-width: 1230px) {
    grid-template-columns: 15% 16% 30% 19% 20%;
  }
}
.section-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  &__text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &--performance {
      margin-top: 5px;
    }
  }
  &__title {
    padding-right: 5px;
    font-weight: bold;
    i {
      color: #ff9900;
      font-style: italic;
      font-size: 23px;
    }
  }
  &__text {
    margin: 0px;
    overflow-wrap: break-word;
    inline-size: 100%;
  }
}

.section-container-text {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
  }
}

.divider {
  position: absolute;
  right: 0;
  height: 90%;
  border-left: 1px solid #dedede;
}
.quote-button {
  width: 100%;
  max-width: 200px;
  margin: 4px auto;
  padding: 5px;
  height: 43px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.price-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: clamp(0.6rem, 2vw, 0.7rem);
  &__label {
    margin: auto;
    width: 50%;
    max-width: 95px;
    font-size: 0.68rem;
  }
  &__prices {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 95px;
    text-align: center;
    word-break: break-all;
    letter-spacing: 0.5px;
    span {
      font-size: 11px;
      margin: auto 0;
    }
    p {
      font-weight: bold;
    }
  }
}
</style>
