<template>
  <div class="insurance-detail-resume">
    <div class="insurance-title">
      <strong>{{ insurance.load.shp }}</strong>
    </div>
    <ul class="menu-items" v-for="(item, index) in insuranceResume" :key="index">
      <li class="menu-items__item">
        <p>
          <strong>{{ item.title }}:</strong> <span>{{ item.value }}</span>
        </p>
      </li>
    </ul>
    <button class="edit-quote--button" @click="changeView('QuoteInsurance')">
      <i class="ion ion-pencil-outline"></i>
      {{ $t("carriers.editQuoteDetails") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "InsuranceDetailResume",
  props: {
    insurance: Object,
  },
  data() {
    return {
      insuranceResume: [],
    };
  },
  watch: {
    insurance: {
      immediate: true,
      handler() {
        if (this.insurance) {
          this.assignInsuranceValues();
        }
      },
    },
  },
  methods: {
    assignInsuranceValues() {
      this.insuranceResume = [
        { title: "Conveyance", value: this.insurance.conveyance },
        {
          title: "Origin",
          // eslint-disable-next-line max-len
          value: `${this.insurance.origin_city}, ${this.insurance.origin_state}, ${this.insurance.origin_zip_code}`,
        },
        {
          title: "Destination",
          // eslint-disable-next-line max-len
          value: `${this.insurance.destination_city}, ${this.insurance.destination_state}, ${this.insurance.destination_zip_code}`,
        },
        // { title: "Estimated Date", value: "" },
        { title: "Commodity", value: this.insurance.commodity },
        { title: "Condition", value: this.insurance.condition },
      ];
    },
    changeView(view) {
      this.$router.push({ name: view, params: { id: this.$route.params.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-detail-resume {
  text-align: start;
}
.insurance-title {
  font-family: "Montserrat";
  display: flex;
  justify-content: flex-start;
  font-size: 22px;
}
.menu-items {
  list-style: none;
  padding: 0px;
  margin: 0px;
  &__item {
    p {
      margin: 6px 0px;
      text-align: start;
      strong {
        margin-right: 7px;
      }
    }
  }
}
.edit-quote--button {
  width: 280px;
  margin: 20px 0px 20px 0px;
  border: none;
  color: $color-white;
  background: $color-primary-trigger-button;
  border-radius: 15px;
  height: 35px;
  @include font-small-button-text;
  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
}
</style>
