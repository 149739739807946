<template>
  <div class="insured-value">
    <label for="insured-value" class="label">
      <strong>Insured value:</strong> <span>${{ insuredValue }}</span></label
    >
  </div>
</template>

<script>
export default {
  name: "InsuredValueInput",
  props: {
    propValue: Number,
  },
  data() {
    return {
      insuredValue: "",
    };
  },
  watch: {
    insuredValue: {
      handler(value) {
        this.insuredValue = this.$options.filters.insurancePrice(value);
      },
    },
  },
  created() {
    this.insuredValue = this.propValue;
  },
  methods: {
    preventChanges() {
      this.insuredValue = this.propValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  width: 100%;
  text-align: start;
  margin: 0px;
}
.input-content {
  margin-top: 5px;
  padding: 5px;
  padding-left: 10px;
  background: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 15px;
  @include font-input-text;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  &__input {
    border: none;
    outline: none;
    width: 100%;
  }
}
.input-content__input:disabled {
  background-color: transparent;
}
</style>
