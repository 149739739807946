<template>
  <div class="rates">
    <div class="carriers-container" id="carriers-container" v-if="screenWidth > 800">
      <RateOption
        v-for="(item, index) in ratesList"
        :key="index"
        :rate="item"
        :selectedInsurance="selectedInsurance"
        @handlerInsuranceAcceptanceModal="handlerInsuranceAcceptanceModal"
      />
    </div>
    <div class="carriers-container" id="carriers-container" v-if="screenWidth <= 800">
      <RateOptionResponsive
        v-for="(item, index) in ratesList"
        :key="index"
        :rate="item"
        :selectedInsurance="selectedInsurance"
        @handlerInsuranceAcceptanceModal="handlerInsuranceAcceptanceModal"
      />
    </div>
    <!-- CustomModal instance outside the sections -->
    <CustomModal
      :centered="true"
      :no-close-on-backdrop="true"
      size="md"
      modalTitle="Insurance Acceptance"
      v-model="acceptanceInsuranceModal"
    >
      <template #modal-content>
        <InsuranceAcceptanceModal @changeView="changeView" :selectedInsurance="selectedInsurance" />
      </template>
    </CustomModal>
  </div>
</template>
<script>
import onReSize from "@/Extend/onResize";
import CustomModal from "../../../../components/CustomModal.vue";
import InsuranceAcceptanceModal from "./InsuranceAcceptanceModal.vue";
import RateOption from "./RateOption.vue";
import RateOptionResponsive from "./RateOptionResponsive.vue";

export default {
  name: "Rates",
  mixins: [onReSize],
  props: {
    selectedInsurance: Object,
  },
  components: {
    RateOption,
    CustomModal,
    InsuranceAcceptanceModal,
    RateOptionResponsive,
  },
  data() {
    return {
      ratesList: [],
      acceptanceInsuranceModal: false,
    };
  },
  created() {
    this.buildRatesList();
  },
  methods: {
    buildRatesList() {
      this.ratesList = [
        {
          carrier_cost: this.selectedInsurance.carrier_cost,
          customer_cost: this.selectedInsurance.customer_cost,
          expiration_date: this.selectedInsurance.expiration_date,
        },
      ];
    },
    handlerInsuranceAcceptanceModal(data) {
      this.acceptanceInsuranceModal = data;
    },
    changeView(view) {
      this.$router.push({ name: view, params: { id: this.$route.params.id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
