<template>
  <div class="rate-option accordion">
    <div class="option-container__info" @click="collapse()" v-b-toggle="'collapse'" role="tab">
      <div class="image-container">
        <img src="../../../../assets/images/cargo-care-logo.png" alt="Insurance" />
      </div>
      <div class="section-container">
        <div class="section-container__text-container">
          <span class="section-container__title">Type of Coverage</span>
          <p class="section-container__text">
            {{ selectedInsurance.condition === "Brand New Goods" ? "All Risk" : "TOTAL LOSS" }}
          </p>
          <p class="section-container__text">
            {{ selectedInsurance.condition === "Brand New Goods" ? "ICC (A)" : "ICC (C)" }}
          </p>
        </div>
      </div>
      <div class="collapse-button">
        <button class="collapse-button__button" :class="isCollapsed">
          <i class="ion ion-chevron-forward-outline collapse-button__icon collapse-icon"></i>
        </button>
      </div>
    </div>
    <b-collapse
      :id="'collapse'"
      class="option-container__collapse"
      accordion="my-accordion"
      role="tabpanel"
    >
      <div class="section-container">
        <div class="section-container__text-container">
          <p class="section-container__title">Special Conditions</p>
          <p class="section-container__text">
            {{ selectedInsurance.deductible }}
          </p>
          <p class="section-container__text">
            Shipments with certain carriers are subject to underwritters approval
          </p>
        </div>
      </div>
      <div class="section-container">
        <div class="section-container__text-container">
          <p class="section-container__title">Quote Expiration Date</p>
          <p class="section-container__text">{{ rate.expiration_date }}</p>
        </div>
      </div>
    </b-collapse>
    <div class="section-container">
      <button
        class="quote-button quote-button--blue"
        @click="selectInsuranceOption()"
      >
        <div class="price-container">
          <div class="price-container__label">BOOK</div>
          <div class="price-container__prices">
            <span>USD{{ rate.customer_cost.toFixed(2) | priceToUsd }}</span>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateOption",
  props: {
    rate: Object,
    selectedInsurance: Object,
  },
  data() {
    return {
      isCollapsed: "is-collapsed",
    };
  },
  methods: {
    changeView(view) {
      this.$router.push({ name: view, params: { id: this.$route.params.id } });
    },
    selectInsuranceOption() {
      this.$emit("handlerInsuranceAcceptanceModal", true);
    },
    collapse() {
      if (this.isCollapsed === "is-collapsed") {
        this.isCollapsed = "not-collapsed";
      } else {
        this.isCollapsed = "is-collapsed";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-option {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 15px;
  background: $color-white;
  padding: 5%;
  position: relative;
  @media (min-width: 1230px) {
    grid-template-columns: 15% 16% 30% 19% 20%;
  }
}
.section-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  &__text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &--performance {
      margin-top: 5px;
    }
  }
  &__title {
    padding-right: 5px;
    font-weight: bold;
    i {
      color: #ff9900;
      font-style: italic;
      font-size: 23px;
    }
  }
  &__text {
    margin: 0px;
    overflow-wrap: break-word;
    inline-size: 100%;
    text-align: left;
  }
  &__title {
    margin: 0px;
    overflow-wrap: break-word;
    inline-size: 100%;
    text-align: left;
  }
}
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
    max-width: 280px;
    object-fit: contain;
  }
}
.quote-button {
  width: 100%;
  min-width: 140px;
  margin: 5px auto;
  padding: 2% 3%;
  height: 40px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
  &__text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 62%;
    }
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.price-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: clamp(0.6rem, 2vw, 0.7rem);
  &__label {
    margin: auto;
    width: 50%;
    max-width: 95px;
    font-size: 0.68rem;
  }
  &__prices {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 95px;
    text-align: center;
    word-break: break-all;
    letter-spacing: 0.5px;
    span {
      font-size: 11px;
      margin: auto 0;
    }
    p {
      font-weight: bold;
    }
  }
}
.collapse-button {
  &__button {
    border: none;
    background: transparent;
  }
  &__icon {
    color: $color-primary-company;
    font-size: 2rem;
  }
}
.collapse-button {
  transform: rotate(0deg);
  transition: 0.3s;
}
.collapse-button .not-collapsed {
  transform: rotate(90deg);
  transition: 0.3s;
}
.option-container {
  &__info {
    display: flex;
  }
}
</style>
